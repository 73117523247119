import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import "./header.css";
import user from "../../assets/images/user-placeholder.png";
import { getAllDashboardApi } from "../../apis/dashboardApi/dashboardApi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function Header() {
  const [value, setValue] = useState();
  const [walletId, setWalletId] = useState();
  async function getAllDashboardApiFunction() {
    const result = await getAllDashboardApi();
    if (result.isSuccess) {
      setValue(result.data.details.name);
      setWalletId(result.data.details.Wallet.address);
    } else {
      console.log("error");
    }
  }

  useEffect(() => {
    getAllDashboardApiFunction();
  }, []);
  return (
    <>
      <div className="flex justify-between py-4 border-b border-gray-300 px-7">
        <div className="">
          <div className="header-logo">
            <img src={Logo} alt="Logo" width={"80%"} />
          </div>
        </div>
        {value && (
          <div className="flex gap-3">
            <CopyToClipboard
              text={walletId}
              onCopy={() => {
                toast.success("Copied successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }}
            >
              <div className="bg-[#f6f6f8] rounded-[15px] px-4 py-2 flex items-center content-center cursor-pointer">
                {walletId?.slice(0, 5)}...{walletId?.slice(-5)}
              </div>
            </CopyToClipboard>{" "}
            {value &&
              (value.profilePhoto ? (
                <img
                  src={value.profilePhoto}
                  alt=""
                  className="rounded-full h-[42px] w-[42px] self-center object-contain"
                />
              ) : (
                <img
                  src={user}
                  alt=""
                  className="rounded-full h-[42px] w-[42px] self-center"
                />
              ))}
            <h4 className="self-center text-base font-normal text-gray-600">
              {value && value}
            </h4>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
