import React, { useEffect, useState } from "react";
import { getAllDashboardApi } from "../../apis/dashboardApi/dashboardApi";
import {
  DeviceIcon,
  GraphIcon,
  GroupIcon,
  RMAIcon,
} from "../../component/icon/icon";
import Layout from "../../component/layout/layout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function Dashboard() {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.authRef.ADMIN_REF);

  const [value, setValue] = useState();

  async function getAllDashboardApiFunction(str) {
    const result = await getAllDashboardApi(str);
    if (result.isSuccess) {
      setValue(result.data);
    } else {
      console.log("error");
    }
  }

  useEffect(() => {
    getAllDashboardApiFunction();
  }, []);

  return (
    <>
      <Layout>
        <div className="flex justify-between gap-5">
          <div className="flex items-end gap-2">
            <h2 className="text-4xl font-semibold text-black"> Hi Admin,</h2>
            <h4 className="text-xl font-normal text-gray-500">
              Explore your Dashboard
            </h4>
          </div>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-4 gap-8">
            <div className="p-4 bg-white rounded-2xl">
              <h4 className="text-3xl font-medium"> {value && value.users} </h4>
              <div className="flex items-center gap-3 mt-3">
                <div className="">
                  <GroupIcon />
                </div>
                <div className="">
                  <h5 className="text-base font-normal text-gray-500">
                    Total Users
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl">
              <h4 className="text-3xl font-medium">
                {" "}
                {value && value.total_investments}{" "}
              </h4>
              <div className="flex items-center gap-3 mt-3">
                <div className="">
                  <GraphIcon />
                </div>
                <div className="">
                  <h5 className="text-base font-normal text-gray-500">
                    Total investment
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl">
              <h4 className="text-3xl font-medium"> {value && value.plans} </h4>
              <div className="flex items-center gap-3 mt-3">
                <div className="">
                  <RMAIcon />
                </div>
                <div className="">
                  <h5 className="text-base font-normal text-gray-500">
                    Number of plans
                  </h5>
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl">
              <h4 className="text-3xl font-medium">
                {" "}
                {value && value.supported_tokens}{" "}
              </h4>
              <div className="flex items-center gap-3 mt-3">
                <div className="">
                  <DeviceIcon />
                </div>
                <div className="">
                  <h5 className="text-base font-normal text-gray-500">
                    Supported tokens
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
