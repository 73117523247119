import axios from "axios";
import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../api";

/* add User api function start */

export const addUsersApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    `/admin/addUser`,
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* add User api function end */

/* get User by ID api function start */

export const getUserByIdApi = async (id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/list-user/${id}`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* get User by ID api function end */

/* get All User api function start */

export const getAllUserApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    "/admin/getAllUser",
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* get All User api function end */

/* Edit User api function start */

export const editUserApi = async (data, id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "put",
    `/admin/editUser/${id}`,
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* Edit User api function end */

/* Remove User api function start */

export const removeUserApi = async (id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    `/admin/removeUser/${id}`,
    id,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* Remove User api function end */

/* add Bulk User api function start */

export const addBulkUsersApi = async (data) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    "/admin/addUserBulk",
    data,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* add Bulk User api function end */

/* get User By Region for Admin filter api function start */

export const getAllUserByRegionApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/list-user`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const getInvestmentsByUserApi = async (id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/investments/${id}`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

/* get User By Region for Admin filter api function start */
