import React from "react";
import PublicRoute from "./routes/publicRoute";

function App() {
  return (
    <>
      <PublicRoute />
    </>
  );
}

export default App;
