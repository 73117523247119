import { ADMIN_FILTER } from "../../action/index.action";

export const demoRef = (state = "", action) => {
  switch (action.type) {
    case ADMIN_FILTER:
      return {
        ...state,
        ADMIN_FILTER: action.payload,
      };
    default:
      return state;
  }
};
