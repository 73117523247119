import axios from "axios";
import { decrypt, encrypt } from "../utils/utils";

export const handleApiCall = async (
  type,
  endpoint,
  data,
  isAuthTokenRequired = false,
  header
) => {
  //   const auth_token = isAuthTokenRequired ? authToken : null;

  //   let headers = {
  //     "content-type": "application/x-www-form-urlencoded",
  //     Accept: "application/json",
  //     Authorization: "Bearer " + auth_token,
  //   };

  return await encrypt(data).then(async (res) => {
    let result;
    const url = process.env.REACT_APP_API_URL + endpoint;
    // if (!isAuthTokenRequired) {
    //   result = await axios.post(process.env.REACT_APP_API_URL + endpoint, res);
    // } else {
    //   result = await axios.post(process.env.REACT_APP_API_URL + endpoint, res, {
    //     headers: headers,
    //   });
    // }
    try {
      switch (type.toLowerCase()) {
        case "post":
          if (!isAuthTokenRequired) {
            result = await axios.post(url, res);
          } else {
            result = await axios.post(url, res, {
              headers: header,
            });
          }
          break;
        case "put":
          if (!isAuthTokenRequired) {
            result = await axios.put(url, res);
          } else {
            result = await axios.put(url, res, {
              headers: header,
            });
          }
          break;
        case "patch":
          if (!isAuthTokenRequired) {
            result = await axios.patch(url, res);
          } else {
            result = await axios.patch(url, res, {
              headers: header,
            });
          }
          break;
        case "get":
          if (!isAuthTokenRequired) {
            result = await axios.get(url);
          } else {
            result = await axios.get(url, {
              headers: header,
            });
          }
          break;
        case "delete":
          if (!isAuthTokenRequired) {
            result = await axios.delete(url);
          } else {
            result = await axios.delete(url, {
              headers: header,
            });
          }
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${type}`);
      }
      return await decrypt(result?.data?.encryptedData, result?.data?.tag).then(
        (apiRes) => {
          console.log("API res", apiRes);
          return apiRes;
        }
      );
    } catch (err) {
      return await decrypt(
        err?.response?.data?.encryptedData,
        err?.response?.data?.tag
      ).then((apiRes) => {
        console.log("API res", apiRes);
        return apiRes;
      });
    }
  });
};
