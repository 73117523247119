import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../api";

export const getPlansApi = async (state) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/get-plans/${
      state === true ? "?state=true" : state == false ? "?state=false" : ""
    }`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const deletePlanApi = async (id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/delete-plan/${id}`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const enableDisablePlanApi = async (id, status) => {
  let header = await authHeader();
  const body = {};
  const val = await handleApiCall(
    "put",
    `/admin/enable-disable-plan/${id}/${status}`,
    body,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const createPlanApi = async (body) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/add-plans`,
    body,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};
