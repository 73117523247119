import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import "./auth.css";
import authbottom from "../../assets/images/auth-bottom-img.png";
import { signinApi } from "../../apis/authApi/authApi";
import MessageBox from "../../component/styledComponents/MessageBox/MessageBox";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_REF,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "../../redux/action/index.action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function Login() {
  const accessToken = useSelector((state) => state.authRef.ACCESS_TOKEN);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handlePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });
  function handleSubmit(e) {
    e.preventDefault();
    if (email.length === 0) {
      setShowApiRes({
        message: "Please Enter email",
        visible: true,
        variant: "danger",
      });
    } else if (password.length < 3) {
      setShowApiRes({
        message: "Please Enter password",
        visible: true,
        variant: "danger",
      });
    } else {
      LoginApiFunction();
    }
  }

  async function LoginApiFunction() {
    const result = await signinApi({
      email: email,
      password: password,
    });
    console.log(result);
    if (result.isSuccess) {
      // setIsVerified(result.isVerified, dispatch);
      // localStorage.setItem("accessToken", result.data.encryAccessToken);
      dispatch({ type: ADMIN_REF, payload: true });
      dispatch({
        type: ACCESS_TOKEN,
        payload: result.data.response.accessToken,
      });
      dispatch({
        type: REFRESH_TOKEN,
        payload: result.data.response.refreshToken,
      });
      window.location.href = "/dashboard";
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      5000
    );
  }, [showApiRes.visible]);

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <div className="flex flex-col justify-center h-full min-h-screen">
        <img
          src={authbottom}
          alt=""
          className=" absolute min-h-[50vh] bottom-0 -z-10 w-full"
        />
        <div className="mx-auto auth-login-container ">
          <div className="flex justify-center">
            {/* <Logo /> */}
            <img src={Logo} alt="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="bg-white shadow-md rounded-2xl py-9 px-7 mt-7">
              <div className="">
                <h5 className="text-base font-normal text-gray-600">
                  User Name
                </h5>
                <input
                  type="email"
                  className="text-black text-lg px-2 py-1 border border-slate-300 rounded-lg w-full mt-1.5 focus:outline-none"
                  placeholder="your@email.com"
                  onChange={handleEmailChange}
                />
              </div>
              <div className="mt-6 ">
                <h5 className="text-base font-normal text-gray-600">
                  Password{" "}
                </h5>
                <input
                  type="password"
                  className="text-black text-lg px-2 py-1 border border-slate-300 rounded-lg w-full mt-1.5 focus:outline-none"
                  placeholder="********"
                  onChange={handlePassword}
                />
              </div>
              <div className="">
                {showApiRes.visible && (
                  <div className="pt-2">
                    <MessageBox
                      width="100%"
                      variant={showApiRes.variant}
                      message={showApiRes.message}
                      title={showApiRes.title}
                    ></MessageBox>
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="w-full p-2 text-lg font-medium text-white mt-9 bg-color-2 rounded-xl"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
