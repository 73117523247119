/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DownLogo } from "../icon/icon";
import { useNavigate } from "react-router-dom";
import Uparrow from "../../assets/images/up_arrow.svg";
import { useSelector } from "react-redux";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "../../redux/action/index.action";
import { useDispatch } from "react-redux";

function Sidebar() {
  const admin = useSelector((state) => state.authRef.ADMIN_REF);
  const accessToken = useSelector((state) => state.authRef.ACCESS_TOKEN);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [active, setActive] = useState("dashboard");
  const [user, setUser] = useState(false);
  const [plan, setPlan] = useState(false);
  const [token, setToken] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "dashboard") {
      setActive("dashboard");
    }else if (window.location.pathname.split("/")[1] === "wallet") {
      setActive("wallet");
    } else if (window.location.pathname.split("/")[1] === "user-management") {
      setActive("userManagement");
    } else if (window.location.pathname.split("/")[1] === "plan-info") {
      setActive("plansData");
    } else if (window.location.pathname.split("/")[1] === "add-plan") {
      setActive("addPlan");
    } else if (window.location.pathname.split("/")[1] === "token-info") {
      setActive("tokensData");
    } else if (window.location.pathname.split("/")[1] === "add-token") {
      setActive("addToken");
    }

    if (window.location.pathname.split("/")[1] === "add-user") {
      setUser(true);
    } else if (window.location.pathname.split("/")[1] === "user-management") {
      setUser(true);
    } else if (window.location.pathname.split("/")[1] === "plan-info") {
      setPlan(true);
    } else if (window.location.pathname.split("/")[1] === "add-plan") {
      setPlan(true);
    }
    else if (window.location.pathname.split("/")[1] === "token-info") {
      setToken(true);
    } else if (window.location.pathname.split("/")[1] === "add-token") {
      setToken(true);
    }

  }, []);

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="w-full py-7 h-full">
        <div className="flex">
          <div
            className={
              active === "dashboard" ? "w-2 bg-color-2 rounded-r-lg" : ""
            }
          ></div>
          <h3
            className={
              active === "dashboard"
                ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1 select-none"
                : "pl-8 py-3 w-full cursor-pointer text-lg font-medium text-black select-none"
            }
            onClick={() => {
              window.location.href = "/dashboard";
            }}
          >
            Dashboard
          </h3>
        </div>
        <div className="flex">
          <div
            className={
              active === "wallet" ? "w-2 bg-color-2 rounded-r-lg" : ""
            }
          ></div>
          <h3
            className={
              active === "wallet"
                ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1 select-none"
                : "pl-8 py-3 w-full cursor-pointer text-lg font-medium text-black select-none"
            }
            onClick={() => {
              window.location.href = "/wallet";
            }}
          >
            Admin Wallet
          </h3>
        </div>

        <div className="flex">
          <h3
            className="pl-8 py-3 w-full cursor-pointer text-lg font-medium text-black select-none	"
            onClick={() => {
              setUser(!user);
            }}
          >
            Users
          </h3>
          <div
            className="px-3 self-center cursor-pointer"
            onClick={() => {
              setUser(!user);
            }}
          >
            {user ? <img src={Uparrow} alt="up" /> : <DownLogo />}
          </div>
        </div>
        {user ? (
          <>
            <div className="flex">
              <div
                className={
                  active === "userManagement"
                    ? "w-2 bg-color-2 rounded-r-lg"
                    : ""
                }
              ></div>
              <h3
                className={
                  active === "userManagement"
                    ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1"
                    : "pl-8 py-3 w-full cursor-pointer text-lg font-normal text-gray-500"
                }
                onClick={() => {
                  window.location.href = "/user-management";
                }}
              >
                Users Management
              </h3>
            </div>

          </>
        ) : (
          <></>
        )}
        <div className="flex">
          <h3
            onClick={() => {
              setPlan(!plan);
            }}
            className="pl-8 py-3 w-full cursor-pointer text-lg font-medium text-black select-none "
          >
            Plans
          </h3>
          <div
            className="px-3 self-center cursor-pointer"
            onClick={() => {
              setPlan(!plan);
            }}
          >
            {plan ? <img src={Uparrow} alt="up" /> : <DownLogo />}
          </div>
        </div>
        {plan ? (
          <>
            <div className="flex">
              <div
                className={
                  active === "plansData" ? "w-2 bg-color-2 rounded-r-lg" : ""
                }
              ></div>
              <h3
                className={
                  active === "plansData"
                    ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1"
                    : "pl-8 py-3 w-full cursor-pointer text-lg font-normal text-gray-500"
                }
                onClick={() => {
                  window.location.href = "/plan-info";
                }}
              >
                Plans Data
              </h3>
            </div>
            {admin && (
              <div className="flex">
                <div
                  className={
                    active === "addPlan" ? "w-2 bg-color-2 rounded-r-lg" : ""
                  }
                ></div>
                <h3
                  className={
                    active === "addPlan"
                      ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1"
                      : "pl-8 py-3 w-full cursor-pointer text-lg font-normal text-gray-500"
                  }
                  onClick={() => {
                    window.location.href = "/add-plan";
                  }}
                >
                  Add Plan
                </h3>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        <div className="flex">
          <h3
            onClick={() => {
              setToken(!token);
            }}
            className="pl-8 py-3 w-full cursor-pointer text-lg font-medium text-black select-none "
          >
            Tokens
          </h3>
          <div
            className="px-3 self-center cursor-pointer"
            onClick={() => {
              setToken(!token);
            }}
          >
            {token ? <img src={Uparrow} alt="up" /> : <DownLogo />}
          </div>
        </div>
        {token ? (
          <>
            <div className="flex">
              <div
                className={
                  active === "tokensData" ? "w-2 bg-color-2 rounded-r-lg" : ""
                }
              ></div>
              <h3
                className={
                  active === "tokensData"
                    ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1"
                    : "pl-8 py-3 w-full cursor-pointer text-lg font-normal text-gray-500"
                }
                onClick={() => {
                  window.location.href = "/token-info";
                }}
              >
                Tokens Data
              </h3>
            </div>
            {admin && (
              <div className="flex">
                <div
                  className={
                    active === "addToken" ? "w-2 bg-color-2 rounded-r-lg" : ""
                  }
                ></div>
                <h3
                  className={
                    active === "addToken"
                      ? "pl-6 py-3 w-full cursor-pointer text-lg font-medium text-color-1"
                      : "pl-8 py-3 w-full cursor-pointer text-lg font-normal text-gray-500"
                  }
                  onClick={() => {
                    window.location.href = "/add-token";
                  }}
                >
                  Add Token
                </h3>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

      </div>
      <div
        className="pl-8 cursor-pointer text-xl font-medium mt-10 text-[#FF0000]"
        onClick={() => {
          dispatch({
            type: ACCESS_TOKEN,
            payload: "",
          });
          dispatch({
            type: REFRESH_TOKEN,
            payload: "",
          });
          window.location.href = "/";
        }}
      >
        Logout
      </div>
    </>
  );
}

export default Sidebar;
