// import { store } from "./../store/store";

// const authHeader = async () => {
//   const token = store.getState().authRef.ACCESS_TOKEN;
//   return {
//     Authorization: `Bearer ${token}`,
//   };
// };

// export default authHeader;

import { refreshTokenApi, accessTokenApi } from "../../apis/authApi/authApi";
import { store } from "./../store/store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../action/index.action";

const accessTokenCheck = async () => {
  const result = await accessTokenApi();
  // console.log(result.valid);
  // let res = refreshTokenCheck(result.valid)
  // return res;
  return result.valid;
};

// const refreshTokenCheck = async (valid) => {
//   if(!valid){
//     const result = await refreshTokenApi();

//     if (!result) {
//         store.dispatch({ type: ACCESS_TOKEN, payload: "" });
//         store.dispatch({ type: REFRESH_TOKEN, payload: "" });
//         return false;

//     } else {
//       console.log(result.data, "refresh token");
//       store.dispatch({ type: ACCESS_TOKEN, payload: result.data.access_token });
//       return true;
//     }
//   }else{
//     return true;
//   }

// };

const authHeader = async () => {
  // const user = JSON.parse(localStorage.getItem("user"));
  const token = store.getState().authRef.ACCESS_TOKEN;
  const goAhead = accessTokenCheck();
  return goAhead.then((res) => {
    if (res) {
      return { Authorization: "Bearer " + token };
    } else {
      store.dispatch({ type: ACCESS_TOKEN, payload: "" });
      store.dispatch({ type: REFRESH_TOKEN, payload: "" });
      window.location.href = "/";
      return false;
    }
  });
};

export default authHeader;
