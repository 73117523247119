import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import UserManagement from "../pages/user/userManagement";
import EditUser from "../pages/user/editUser";
import AddToken from "../pages/token/addToken";
import TokenInfo from "../pages/token/tokenInfo";
import PlanInfo from "../pages/plan/planInfo";
import AddPlan from "../pages/plan/addPlan";
import Wallet from '../pages/wallet';

function PublicRoute() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/user-info/:id" element={<EditUser />} />
          <Route path="/add-plan" element={<AddPlan />} />
          <Route path="/plan-info" element={<PlanInfo />} />
          <Route path="/add-token" element={<AddToken />} />
          <Route path="/token-info" element={<TokenInfo />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoute;
