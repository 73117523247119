import React, { useEffect, useState } from "react";
import { getAdminAssetsApi } from "../../apis/tokensApi/tokensApi";
import Layout from "../../component/layout/layout";
import AssetTable from "../../component/table/adminWalletTable";
function UserManagement() {
  const [assets, setAssets] = useState([]);
  async function getAdminAssets() {
    const result = await getAdminAssetsApi();
    console.log(result);
    if (result.status === 200) {
      setAssets(result.data.response.result);
    } else {
      console.log("error");
    }
  }
  const columns = [
    {
      Header: "Symbol",
      accessor: "symbol",
    },
    {
      Header: "Name",
      accessor: "name",
    },

    {
      Header: "Live Price",
      accessor: "livePrice",
    },
    {
      Header: "Balance",
      accessor: "balance",
    },
  ];

  useEffect(() => {
    getAdminAssets();
  }, []);

  return (
    <Layout>
      <div className="flex justify-between px-4">
        <h5 className=" mb-3 text-2xl text-black font-medium">Asset Details</h5>
      </div>
      <div className="bg-white rounded-2xl py-5 mt-8 overflow-x-auto">
        <div className="px-5 text-xl text-black font-normal mb-5">Assets</div>

        {assets && assets.length ? (
          <AssetTable columns={columns} data={assets} />
        ) : (
          <div className="pl-4">No data found</div>
        )}
      </div>
    </Layout>
  );
}

export default UserManagement;
