import React, { useState } from "react";

function Dropdown(props) {
  const mapping = props.region;
  const handleClick = () => {
    setShowList(!showList);
  };

  const [curr, setCurr] = useState({
    region: props.selected,
  });

  const [showList, setShowList] = useState(false);

  return (
    <div
      className="w-full flex flex-col items-center relative mt-2"
      onMouseLeave={() => setShowList(false)}
    >
      <div
        onClick={handleClick}
        className="dropbtn w-full px-3 py-2 rounded-xl border border-gray-200 flex justify-between cursor-pointer select-none"
      >
        {console.log(curr.region)}
        <div className="font-normal text-md dropbtn">{curr.region ? curr.region : `Please Select ${props.str}`}</div>

        <div className=" flex items-center justify-center">
          {!showList && (
            <svg
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.02302 0.512376C1.3512 0.184301 1.79624 -7.07798e-07 2.26027 -6.87514e-07C2.72431 -6.67231e-07 3.16935 0.184302 3.49752 0.512376L9.26027 6.27513L15.023 0.512376C15.3531 0.1936 15.7951 0.0172089 16.254 0.0211962C16.7128 0.0251834 17.1517 0.20923 17.4762 0.533694C17.8007 0.858158 17.9847 1.29708 17.9887 1.75592C17.9927 2.21477 17.8163 2.65682 17.4975 2.98688L10.4975 9.98688C10.1693 10.3149 9.72431 10.4993 9.26027 10.4993C8.79624 10.4993 8.3512 10.3149 8.02302 9.98688L1.02302 2.98688C0.694948 2.6587 0.510647 2.21366 0.510647 1.74963C0.510647 1.28559 0.694948 0.840549 1.02302 0.512376Z"
                fill="#002C3A"
              />
            </svg>
          )}
        </div>
      </div>

      {showList && (
        <div
          className="z-10 dropdown origin-top-right absolute top-10 bg-[#ffffff] w-[100%] py-2 rounded-[20px] border-[1px] border-gray-200 shadow-[0px_3px_10px_#999999] opacity-100"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="">
            {mapping &&
              mapping.map((val, key) => (
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    setCurr((old) => ({
                      ...old,
                      region: val,
                    }));
                    props.xyz(props.str, val);
                    setShowList(false);
                  }}
                >
                  <div className="font-normal text-md flex w-full py-2 px-4 hover:bg-[#DDDDDD]">
                    <p>{val}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
