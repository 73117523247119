import React, { useState, useEffect } from "react";
import Layout from "../../component/layout/layout";
import { toast } from "react-toastify";
import MessageBox from "../../component/styledComponents/MessageBox/MessageBox";
import "react-toastify/dist/ReactToastify.css";
import { createPlanApi } from "../../apis/plansApi/plansApi";
import Dropdown from "../../component/dropdown/dropdown";

toast.configure();

const AddPlan = () => {
  const risk = ["HIGH", "MEDIUM", "LOW"];
  const [sale, setSale] = useState({
    planName: "",
    risk: "",
    fixedApy: "",
    rewardsApy: "",
    startDate: "",
    endDate: "",
    totalInvestment: "",
  });

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  async function addSaleFunction(finalSale) {
    const data = finalSale;
    console.log(data);
    const result = await createPlanApi(data);
    // result && setUsers([]);
    if (result.isSuccess) {
      toast.success("Sale added!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setTimeout(() => window.location.reload(false), 4000);
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: "Error",
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (sale.purchaseOrderNumber === "") {
      setShowApiRes({
        message: "Please Enter Purchase Ordernumber",
        visible: true,
        variant: "danger",
      });
    } else if (sale.name === "") {
      setShowApiRes({
        message: "Please enter name",
        visible: true,
        variant: "danger",
      });
    } else if (sale.startDate === "") {
      setShowApiRes({
        message: "Please enter Date of buying",
        visible: true,
        variant: "danger",
      });
    } else if (sale.endDate === "") {
      setShowApiRes({
        message: "Please enter Date of expiry",
        visible: true,
        variant: "danger",
      });
    } else {
      console.log(sale);
      addSaleFunction(sale);
    }
  }

  function handleChange(val, name) {
    setSale((old) => ({ ...old, [name]: val }));
  }
  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  function xyz(str, x) {
    setSale((old) => ({ ...old, [str]: x }));
  }

  return (
    <Layout>
      <div className="">
        <div className="flex justify-between px-4">
          <h2 className="text-2xl font-medium text-black"> Add Plan </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col px-10 py-8 mt-8 bg-white rounded-2xl gap-x-10 gap-y-8">
            <div className="w-[70%] grid grid-cols-2 gap-x-10 gap-y-8">
              <div className="">
                <h5 className="text-base font-medium"> Start Date </h5>
                <input
                  required
                  type="date"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter start date"
                  name="startDate"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="">
                <h5 className="text-base font-medium"> Date of Expiry </h5>
                <input
                  required
                  type="date"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  //   placeholder="Enter Date of buying"
                  name="endDate"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Fixed APY </h5>
                <input
                  required
                  type="number"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter fixed APY"
                  // value={fixedApy}
                  name="fixedApy"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Rewards APY </h5>
                <input
                  required
                  type="number"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter rewards APY"
                  // value={rewardsApy}
                  name="rewardsApy"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>

              <div className="">
                <h5 className="text-base font-medium"> Fixed Investment </h5>
                <input
                  required
                  name="totalInvestment"
                  type="number"
                  // disabled
                  // value={sale.purchaseOrderNumber}
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter Max Investment by user"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Plan name </h5>
                <input
                  required
                  name="planName"
                  type="text"
                  // value={sale.name}
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter name of the plan"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                    // onChangeHandlerCustomer(e.target.value);
                  }}
                />
              </div>

              <div className="col-span-1">
                <h5 className="text-base font-medium">Risk </h5>
                <Dropdown region={risk} str="risk" xyz={xyz} />
              </div>
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="pt-2">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="grid grid-cols-4 gap-6 ">
              <button
                className="py-2 text-lg font-medium text-white rounded-lg bg-color-2"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddPlan;
