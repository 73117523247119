import { combineReducers } from "redux";
import { demoRef } from "./demo/reducer.demo";
import { authRef } from "./auth/reducer.auth";

const rootReducer = combineReducers({
  demoRef: demoRef,
  authRef: authRef,
});

export default rootReducer;
