import React, { useEffect, useState } from "react";
import Layout from "../../component/layout/layout";
import { getTokensApi } from "../../apis/tokensApi/tokensApi";
import { useSelector } from "react-redux";
import TokensTable from "../../component/table/tokensTable";

function SaleInfo() {
  const filter = useSelector((state) => state.demoRef.ADMIN_FILTER);
  const admin = useSelector((state) => state.authRef.ADMIN_REF);
  const [status, setStatus] = useState("all");

  const [tokens, setTokens] = useState([]);
  async function getTokens(status) {
    let state =
      status === "enabled" ? true : status === "disabled" ? false : null;
    const result = await getTokensApi(state);
    console.log(result);
    setTokens(result.data.response);
  }

  useEffect(() => {
    if (admin) {
      getTokens(true);
    } else {
      getTokens(true);
    }
  }, [admin]);
  const columns = [
    {
      Header: "Logo",
      accessor: "logo",
    },
    {
      Header: "Token",
      accessor: "name",
    },
    {
      Header: "Symbol",
      accessor: "symbol",
    },

    {
      Header: "Contract Address",
      accessor: "contractAddress",
    },
    {
      Header: "Status",
      accessor: "enabled",
    },
  ];
  useEffect(() => {
    getTokens(status);
  }, [status]);
  const data = tokens;
  return (
    <Layout>
      <div className="">
        <div className="flex justify-between px-4">
          <h2 className=" text-2xl text-black font-medium">
            Tokens Information
          </h2>
        </div>
        <div className="py-4 rounded-2xl bg-white mt-7 overflow-x-auto">
          <h4 className="px-4 pt-2 text-xl text-black font-normal mb-5">
            Tokens
          </h4>
          <div className="px-4 flex gap-4 pt-2 text-sm text-black font-normal mb-5">
            <div
              className={` text-center w-[100px] border border-2 cursor-pointer px-2 py-2 rounded-2xl ${
                status === "all" && "border-sky-600 text-color-1"
              }`}
              onClick={() => setStatus("all")}
            >
              All
            </div>
            <div
              className={` text-center w-[100px] border border-2 cursor-pointer px-2 py-2 rounded-2xl ${
                status === "enabled" && "border-sky-600 text-color-1"
              }`}
              onClick={() => setStatus("enabled")}
            >
              Enabled
            </div>
            <div
              className={` text-center w-[100px] border border-2 cursor-pointer px-2 py-2 rounded-2xl ${
                status === "disabled" && " border-sky-600 text-color-1"
              }`}
              onClick={() => setStatus("disabled")}
            >
              Disabled
            </div>
          </div>
          {data && data.length ? (
            <TokensTable columns={columns} data={data} />
          ) : (
            <div className="pl-4">No data found</div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default SaleInfo;
