import axios from "axios";
import authHeader from "../../redux/services/auth-header";
import { handleApiCall } from "../api";

export const getTokensApi = async (state) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/get-tokens/${
      state === true ? "?state=true" : state == false ? "?state=false" : ""
    }`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const deleteTokenApi = async (id) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "delete",
    `/admin/delete-token/${id}`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const enableDisableTokenApi = async (id, status) => {
  let header = await authHeader();
  const body = {};
  const val = await handleApiCall(
    "put",
    `/admin/enable-disable-token/${id}/${status}`,
    body,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const createTokenApi = async (body) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    `/admin/add-tokens`,
    body,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const uploadImageApi = async (body) => {
  let header = await authHeader();
  const val = await handleApiCall(
    "post",
    `/admin/upload-photo`,
    body,
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};

export const getAdminAssetsApi = async () => {
  let header = await authHeader();
  const val = await handleApiCall(
    "get",
    `/admin/get-assets`,
    {},
    true,
    header
  ).then((res) => {
    return res;
  });
  return val;
};
