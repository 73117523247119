import React, { useState, useEffect } from "react";
import Layout from "../../component/layout/layout";
import { toast } from "react-toastify";
import MessageBox from "../../component/styledComponents/MessageBox/MessageBox";
import "react-toastify/dist/ReactToastify.css";
import { uploadImageApi } from "../../apis/tokensApi/tokensApi";
import { useSelector } from "react-redux";
import { createTokenApi } from "../../apis/tokensApi/tokensApi";

toast.configure();

const AddToken = () => {
  const [token, setToken] = useState({
    contractAddress: "",
    name: "",
    symbol: "",
    logo: "",
    description: "",
  });

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });

  async function addTokenFunction(finalToken) {
    const data = finalToken;

    const result = await createTokenApi(data);
    // result && setUsers([]);
    if (result.isSuccess) {
      toast.success("Token added!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setTimeout(() => window.location.reload(false), 4000);
    } else {
      setShowApiRes({
        message: result?.message || "Something went wrong!",
        visible: true,
        variant: "danger",
        title: result?.status || "Error",
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (token.purchaseOrderNumber === "") {
      setShowApiRes({
        message: "Please Enter Purchase Ordernumber",
        visible: true,
        variant: "danger",
      });
    } else if (token.name === "") {
      setShowApiRes({
        message: "Please enter name",
        visible: true,
        variant: "danger",
      });
    } else if (token.startDate === "") {
      setShowApiRes({
        message: "Please enter Date of buying",
        visible: true,
        variant: "danger",
      });
    } else if (token.endDate === "") {
      setShowApiRes({
        message: "Please enter Date of expiry",
        visible: true,
        variant: "danger",
      });
    } else {
      // finalSale["sales"] = sale;
      console.log(token);
      addTokenFunction(token);
    }
  }

  function handleChange(val, name) {
    console.log(val, name);
    setToken((old) => ({ ...old, [name]: val }));
  }
  async function handleChangeImage(e) {
    const name = e.target.name;
    e.preventDefault();
    const img = e.target.files[0];
    console.log(img);
    let fd = new FormData();
    console.log(fd);
    fd.append("file", img);
    console.log(fd);
    const result = await uploadImageApi(fd);
    console.log(result);
    if (result.isSuccess) {
      console.log("inside if");
      handleChange(result.data.url, name);
      if (result === false) {
        console.log("Error");
      }
    }
  }
  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      3000
    );
  }, [showApiRes.visible]);

  return (
    <Layout>
      <div className="">
        <div className="flex justify-between px-4">
          <h2 className="text-2xl font-medium text-black"> Add Token </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col px-10 py-8 mt-8 bg-white rounded-2xl gap-x-10 gap-y-8">
            <div className="w-[70%] grid grid-cols-2 gap-x-10 gap-y-8">
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Contract Address </h5>
                <input
                  required
                  type="text"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter contract address"
                  // value={fixedApy}
                  name="contractAddress"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Symbol </h5>
                <input
                  required
                  type="text"
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter symbol"
                  // value={rewardsApy}
                  name="symbol"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>

              <div className="relative z-5">
                <h5 className="text-base font-medium"> Logo </h5>
                <input
                  type="file"
                  id="files"
                  name="logo"
                  placeholder="Upload"
                  className="hidden"
                  onChange={handleChangeImage}
                />
                <label
                  htmlFor="files"
                  className="flex items-center justify-center border border-gray-200  rounded-2xl px-8 mt-2 py-2 text-base font-medium  font-[18px]"
                >
                  Upload
                </label>
              </div>
              <div className="relative z-5">
                <h5 className="text-base font-medium"> Token name </h5>
                <input
                  required
                  name="name"
                  type="text"
                  // value={sale.name}
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  placeholder="Enter name of the token"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                    // onChangeHandlerCustomer(e.target.value);
                  }}
                />
              </div>

              <div className="">
                <h5 className="text-base font-medium">Description </h5>
                <textarea
                  required
                  name="description"
                  type="text"
                  // disabled
                  // value={sale.purchaseOrderNumber}
                  className="w-full px-3 py-2 mt-2 border border-gray-200 rounded-xl"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="">
              {showApiRes.visible && (
                <div className="pt-2">
                  <MessageBox
                    width="100%"
                    variant={showApiRes.variant}
                    message={showApiRes.message}
                    title={showApiRes.title}
                  ></MessageBox>
                </div>
              )}
            </div>
            <div className="grid grid-cols-4 gap-6 ">
              <button
                className="py-2 text-lg font-medium text-white rounded-lg bg-color-2"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddToken;
