import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useFilters, // useFilters!
  useGlobalFilter,
  useSortBy,
} from "react-table";
import React, { useState } from "react";
import EditUser from "../../pages/user/editUser";
import { matchSorter } from "match-sorter";
import { removeUserApi } from "../../apis/usersApi/usersApi";
import { EditIcon } from "../icon/icon";
import Confirmation from "../modal/Confirmation";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="px-2 py-1 my-2 ml-2 border border-black pagination-search rounded-xl"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function UserTable({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [userId, setUserId] = useState();
  async function handleClick(data) {
    const result = await removeUserApi(data);
    if (result.isSuccess) {
      setTimeout(() => window.location.reload(false), 4000);
      toast.info("User Removed successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      console.log("error in removing user");
    }
  }
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const admin = useSelector((state) => state.authRef.ADMIN_REF);

  // Render the UI for your table
  return (
    <>
      <div className="flex justify-end mb-2 mb-4 mr-4 -mt-14">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table {...getTableProps()} className="w-full text-center table-auto">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="bg-color-4 rounded-2xl"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-3 font-medium text-left text-black text-bamd"
                >
                  <div className="flex">
                    <h5 className="">{column.render("Header")}</h5>
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                </th>
              ))}
              <th></th>
              <th></th>
            </tr>
          ))}
          {/* <tr className="hidden">
            <th colSpan={visibleColumns.length + 1} className="">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr> */}
        </thead>
        <tbody {...getTableBodyProps()} className="">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 font-normal text-left text-black text-md"
                    >
                      <h5 className="py-1">{cell.render("Cell")}</h5>
                    </td>
                  );
                })}
                {admin && (
                  <td className="px-3">
                    <button
                      className="text-white px-4 py-1.5 my-1.5 rounded-2xl bg-color-2 flex"
                      onClick={() => {
                        window.location.href = `/user-info/${row.original._id}`;
                      }}
                    >
                      <h5 className="">Info</h5>
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}{" "}
      <div className="flex justify-end gap-5 mt-5 pr-7">
        <div
          className="px-3 py-1 text-lg font-bold text-white rounded-full cursor-pointer bg-color-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </div>

        {pageOptions.length > 4 ? (
          pageIndex === 0 || pageIndex === 1 ? (
            <div className="flex gap-2">
              <h6
                className={
                  pageIndex === 0
                    ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                    : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                }
                onClick={() => gotoPage(0)}
              >
                1
              </h6>
              <h6
                className={
                  pageIndex === 1
                    ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                    : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                }
                onClick={() => gotoPage(1)}
              >
                2
              </h6>
              <h6 className="font-semibold text-black cursor-pointer ">...</h6>
              <h6
                className="px-3 py-1 text-lg border rounded-full cursor-pointer text-sky-600 border-sky-600"
                onClick={() => gotoPage(pageOptions.length - 1)}
              >
                {pageOptions.length}
              </h6>
            </div>
          ) : pageIndex === pageOptions.length - 1 ||
            pageIndex === pageOptions.length - 2 ? (
            <div className="flex gap-2">
              <h6
                className="px-3 py-1 text-lg border rounded-full cursor-pointer text-sky-600 border-sky-600"
                onClick={() => gotoPage(0)}
              >
                1
              </h6>
              <h6 className="font-semibold text-black cursor-pointer ">...</h6>
              <h6
                className={
                  pageIndex === pageOptions.length - 2
                    ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                    : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                }
                onClick={() => gotoPage(pageOptions.length - 2)}
              >
                {pageOptions.length - 1}
              </h6>
              <h6
                className={
                  pageIndex === pageOptions.length - 1
                    ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                    : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                }
                onClick={() => gotoPage(pageOptions.length - 1)}
              >
                {pageOptions.length}
              </h6>
            </div>
          ) : (
            <div className="flex gap-2">
              <h6
                className="px-3 py-1 text-lg border rounded-full cursor-pointer text-sky-600 border-sky-600"
                onClick={() => gotoPage(0)}
              >
                1
              </h6>
              <h6 className="font-semibold text-black cursor-pointer ">...</h6>
              <h6
                className="cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                onClick={() => gotoPage(pageIndex)}
              >
                {pageIndex + 1}
              </h6>
              <h6 className="font-semibold text-black cursor-pointer">...</h6>
              <h6
                className="px-3 py-1 text-lg border rounded-full cursor-pointer text-sky-600 border-sky-600"
                onClick={() => gotoPage(pageOptions.length - 1)}
              >
                {pageOptions.length}
              </h6>
            </div>
          )
        ) : (
          [...Array(pageOptions.length)].map((item, index) => (
            <h6
              key={index}
              className={
                pageIndex === index
                  ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                  : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
              }
              onClick={() => {
                gotoPage(index);
              }}
            >
              {index + 1}
            </h6>
          ))
        )}
        <div
          className="px-3 py-1 text-lg font-bold text-white rounded-full cursor-pointer bg-color-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </div>
      </div>
    </>
  );
}

export default UserTable;
