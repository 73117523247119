import axios from "axios";
import { store } from "../../redux/store/store";
import { handleApiCall } from "../api";

/* signup api function start */

export const signupApi = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/admin/signup", data)
    .then((res) => {
      return res;
    })
    .catch(function (err) {
      return err.response;
    });

  return result;
};

/* signup api function end */

/* signin api function start */

export const signinApi = async (data) => {
  const val = await handleApiCall(
    "post",
    "/admin/admin-login",
    data,
    false,
    ""
  ).then((res) => {
    return res;
  });
  return val;
};

/* signin api function end */

/* refreshToken api function start */

export const refreshTokenApi = async () => {
  const token = store.getState().authRef.REFRESH_TOKEN;
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/admin/refreshToken", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

/* refreshToken api function start */

/* accessToken api function start */

export const accessTokenApi = async () => {
  const token = store.getState().authRef.ACCESS_TOKEN;
  const val = await handleApiCall("get", "/admin/token-validator", {}, true, {
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    console.log("Res", res);
    return res;
  });
  return val;
};

/* accessToken api function start */
