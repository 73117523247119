import React, { useEffect, useState } from "react";
import UserTable from "../../component/table/userTable";
import {
  getAllUserApi,
  getAllUserByRegionApi,
} from "../../apis/usersApi/usersApi";
import Layout from "../../component/layout/layout";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

function UserManagement() {
  const filter = useSelector((state) => state.demoRef.ADMIN_FILTER);
  const admin = useSelector((state) => state.authRef.ADMIN_REF);

  const [user, setUser] = useState([]);
  async function getUsers() {
    const result = await getAllUserApi();
    setUser(result.data);
  }
  async function getUsersByRegion(region) {
    const result = await getAllUserByRegionApi(region);
    console.log(result);
    setUser(result.data.response);
  }
  const data = user;
  const columns = [
    {
      Header: "UserName",
      accessor: "name",
    },
    {
      Header: "UserId",
      accessor: "_id",
    },

    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Wallet Address",
      accessor: "wallet_details.address",
    },
  ];

  const header = [
    {
      label: "User Name",
      key: "userName",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Role",
      key: "role",
    },
    {
      label: "User Id",
      key: "userId",
    },
    {
      label: "Region",
      key: "region",
    },
  ];

  useEffect(() => {
    if (admin) {
      getUsersByRegion(filter);
    } else {
      getUsers();
    }
  }, [admin]);

  return (
    <Layout>
      <div className="flex justify-between px-4">
        <h5 className=" mb-3 text-2xl text-black font-medium">
          User Management
        </h5>
      </div>
      <div className="bg-white rounded-2xl py-5 mt-8 overflow-x-auto">
        <div className="px-5 text-xl text-black font-normal mb-5">Users</div>

        {data ? (
          <UserTable columns={columns} data={data.reverse()} />
        ) : (
          <div className="pl-4">No data found</div>
        )}
      </div>
    </Layout>
  );
}

export default UserManagement;
