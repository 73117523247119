import {
    useTable,
    usePagination,
    useAsyncDebounce,
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
  } from "react-table";
  import React from "react";
  import { matchSorter } from "match-sorter";
  
  // Define a default UI for filtering
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
  
    return (
      <span>
        Search:{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="pagination-search border border-black px-2 py-1 rounded-xl ml-2 my-2"
        />
      </span>
    );
  }
  
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
  
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }
  
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }
  
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;
  
  function AssetTable({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );
  
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      state: { pageIndex },
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    // Render the UI for your table
    return (
      <>
        <div className="flex justify-end mr-4 -mt-14 mb-4 mb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table {...getTableProps()} className="table-auto w-full text-center">
          <thead>
            {/* <tr className="">
              <th colSpan={visibleColumns.length + 1} className="">
                
              </th>
            </tr> */}
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-color-4 rounded-2xl"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="text-left text-black font-medium text-base px-3 py-3"
                  >
                    <div className="flex">
                      <h5 className="">{column.render("Header")}</h5>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="text-left text-black font-normal text-sm px-3"
                      >
                          <h5 className="py-1 my-2">{cell.render("Cell")}</h5>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="mt-5 flex gap-5 justify-end pr-7">
          <div
            className="cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            &#x2B9C;
          </div>
  
          {pageOptions.length > 4 ? (
            pageIndex === 0 || pageIndex === 1 ? (
              <div className="flex gap-2">
                <h6
                  className={
                    pageIndex === 0
                      ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                      : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  }
                  onClick={() => gotoPage(0)}
                >
                  1
                </h6>
                <h6
                  className={
                    pageIndex === 1
                      ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                      : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  }
                  onClick={() => gotoPage(1)}
                >
                  2
                </h6>
                <h6 className="cursor-pointer text-black font-semibold ">...</h6>
                <h6
                  className="cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  onClick={() => gotoPage(pageOptions.length - 1)}
                >
                  {pageOptions.length}
                </h6>
              </div>
            ) : pageIndex === pageOptions.length - 1 ||
              pageIndex === pageOptions.length - 2 ? (
              <div className="flex gap-2">
                <h6
                  className="cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  onClick={() => gotoPage(0)}
                >
                  1
                </h6>
                <h6 className="cursor-pointer text-black font-semibold ">...</h6>
                <h6
                  className={
                    pageIndex === pageOptions.length - 2
                      ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                      : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  }
                  onClick={() => gotoPage(pageOptions.length - 2)}
                >
                  {pageOptions.length - 1}
                </h6>
                <h6
                  className={
                    pageIndex === pageOptions.length - 1
                      ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                      : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  }
                  onClick={() => gotoPage(pageOptions.length - 1)}
                >
                  {pageOptions.length}
                </h6>
              </div>
            ) : (
              <div className="flex gap-2">
                <h6
                  className="cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  onClick={() => gotoPage(0)}
                >
                  1
                </h6>
                <h6 className="cursor-pointer text-black font-semibold ">...</h6>
                <h6
                  className="cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                  onClick={() => gotoPage(pageIndex)}
                >
                  {pageIndex + 1}
                </h6>
                <h6 className="cursor-pointer text-black font-semibold">...</h6>
                <h6
                  className="cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                  onClick={() => gotoPage(pageOptions.length - 1)}
                >
                  {pageOptions.length}
                </h6>
              </div>
            )
          ) : (
            [...Array(pageOptions.length)].map((item, index) => (
              <h6
                key={index}
                className={
                  pageIndex === index
                    ? "cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3.5"
                    : "cursor-pointer text-lg text-sky-600 rounded-full py-1 px-3 border border-sky-600"
                }
                onClick={() => {
                  gotoPage(index);
                }}
              >
                {index + 1}
              </h6>
            ))
          )}
          <div
            className="cursor-pointer text-lg text-white bg-color-2 rounded-full py-1 px-3"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            &#x2B9E;
          </div>
        </div>
      </>
    );
  }
  
  export default AssetTable;
  