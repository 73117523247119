import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const errorToast = (message) => {
  /** error alert is fired from here */
  toast.error(message);
};

export const infoToast = (message) => {
  /** info alert is fired from here */
  toast.info(message);
};

export const successToast = (message) => {
  /** success alert is fired from here*/
  toast.success(message);
};

////// FINALLLLLLL Decrypt

export async function encrypt(plaintext) {
  let KEY = hexStringToArrayBuffer(process.env.REACT_APP_YETI_API_SECRET);
  let IV = hexStringToArrayBuffer(process.env.REACT_APP_YETI_PASS_PHRASE);

  let encodedString = new TextEncoder().encode(JSON.stringify(plaintext));

  return await window.crypto.subtle
    .importKey("raw", KEY, { name: "AES-GCM", length: 256 }, true, ["encrypt"])
    .then(async (importedKey) => {
      return await window.crypto.subtle
        .encrypt(
          {
            name: "AES-GCM",
            iv: IV,
          },
          importedKey,
          encodedString
        )
        .then(async (encrypted) => {
          let encryptedHex = arrayBufferToHexString(encrypted);
          const tag = encryptedHex.slice(-32); // Last 16 bytes are the tag
          const encryptedData = encryptedHex.slice(0, -32); // Remove the tag
          console.log("Encrypted hex string: ", tag, encryptedData);
          return { encryptedData, tag };
        });
    });

  function hexStringToArrayBuffer(hexString) {
    hexString = hexString.replace(/^0x/, "");
    if (hexString.length % 2 != 0) {
      console.log(
        "WARNING: expecting an even number of characters in the hexString"
      );
    }
    var bad = hexString.match(/[G-Z\s]/i);
    if (bad) {
      console.log("WARNING: found non-hex characters", bad);
    }
    var pairs = hexString.match(/[\dA-F]{2}/gi);
    var integers = pairs.map(function (s) {
      return parseInt(s, 16);
    });
    var array = new Uint8Array(integers);
    return array.buffer;
  }

  function arrayBufferToHexString(buffer) {
    var byteArray = new Uint8Array(buffer);
    var hexString = "";
    for (var i = 0; i < byteArray.byteLength; i++) {
      hexString += ("0" + byteArray[i].toString(16)).slice(-2);
    }
    return hexString;
  }
}

export async function decrypt(encryptedData, tag) {
  let KEY = hexStringToArrayBuffer(process.env.REACT_APP_YETI_API_SECRET);
  let IV = hexStringToArrayBuffer(process.env.REACT_APP_YETI_PASS_PHRASE);
  // let encrypted = hexStringToArrayBuffer(
  //   "397657804998bcb00bc78f1d8766676f44dbcc1864299bcd192cfeacd9cb12" +
  //     "6f44dbcc1864299bcd192cfeacd9cb12"
  // );
  // let encrypted = hexStringToArrayBuffer(
  //   "193b50874d92a4b27e878015857c58923783a1c5d5eb27a8470e36bc68c64efc1a4099f32a0078fdba3292670159e4c8b4b700b660aa4aa59d" +
  //     "018a2a5ff71af5af0316c2c8abbd8ed1"
  // );
  let encrypted = hexStringToArrayBuffer(encryptedData + tag);

  return await window.crypto.subtle
    .importKey("raw", KEY, "AES-GCM", true, ["decrypt"])
    .then(async (importedKey) => {
      return await window.crypto.subtle
        .decrypt(
          {
            name: "AES-GCM",
            iv: IV,
          },
          importedKey,
          encrypted
        )
        .then((decodedBuffer) => {
          let plaintext = new TextDecoder("utf8").decode(decodedBuffer);
          console.log("plainText: ", JSON.parse(plaintext));
          return JSON.parse(plaintext);
        });
    });

  function hexStringToArrayBuffer(hexString) {
    hexString = hexString.replace(/^0x/, "");
    if (hexString.length % 2 != 0) {
      console.log(
        "WARNING: expecting an even number of characters in the hexString"
      );
    }
    var bad = hexString.match(/[G-Z\s]/i);
    if (bad) {
      console.log("WARNING: found non-hex characters", bad);
    }
    var pairs = hexString.match(/[\dA-F]{2}/gi);
    var integers = pairs.map(function (s) {
      return parseInt(s, 16);
    });
    var array = new Uint8Array(integers);
    return array.buffer;
  }
}
