import { AUTH_REF } from "../../action/index.action";
import { ADMIN_REF } from "../../action/index.action";
import { ACCESS_TOKEN } from "../../action/index.action";
import { REFRESH_TOKEN } from "../../action/index.action";

export const authRef = (state = "", action) => {
  switch (action.type) {
    case ADMIN_REF:
      return {
        ...state,
        ADMIN_REF: action.payload,
      };
    case ACCESS_TOKEN:
      return {
        ...state,
        ACCESS_TOKEN: action.payload,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        REFRESH_TOKEN: action.payload,
      };
    default:
      return state;
  }
};
