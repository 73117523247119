import React, { useEffect, useState } from "react";
import Layout from "../../component/layout/layout";
import { useParams } from "react-router-dom";
import {
  getUserByIdApi,
  editUserApi,
  getInvestmentsByUserApi,
} from "../../apis/usersApi/usersApi";
import { toast } from "react-toastify";
import Dropdown from "../../component/dropdown/editDropdown";
import { useNavigate } from "react-router-dom";
import { BackIcon, EditIcon } from "../../component/icon/icon";
import MessageBox from "../../component/styledComponents/MessageBox/MessageBox";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import InvestmentsTable from "../../component/table/investmentsTable";
toast.configure();

const EditUser = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [userInvestments, setUserInvestments] = useState();

  const [fetchTable, setFetchTable] = useState(false);

  const [showApiRes, setShowApiRes] = useState({
    message: "",
    visible: false,
    variant: "information",
    title: "",
  });
  async function getInvestmentsByUserApiFunction(userId) {
    const result = userId && (await getInvestmentsByUserApi(userId));
    if (result.isSuccess) {
      setUserInvestments(result.data.response);
    } else {
    }
  }

  async function getUserByIdFunction(id) {
    setLoading(true);
    const result = await getUserByIdApi(id);
    if (result.isSuccess) {
      setLoading(false);
      setUser(result.data.response[0]);
      const userObj = result.data.response[0];
      result && !loading && getInvestmentsByUserApiFunction(userObj["_id"]);

      setFetchTable(true);
    } else {
    }
  }

  const columns = [
    {
      Header: "Plan Name",
      accessor: "investmentPlanId.planName",
    },
    {
      Header: "Start Date",
      accessor: "investmentPlanId.startDate",
    },
    {
      Header: "End Date",
      accessor: "investmentPlanId.endDate",
    },
    {
      Header: "Fixed APY",
      accessor: "investmentPlanId.fixedApy",
    },
    {
      Header: "Rewards APY",
      accessor: "investmentPlanId.rewardsApy",
    },
    {
      Header: "Risk",
      accessor: "investmentPlanId.risk",
    },
    {
      Header: "Max Investments",
      accessor: "investmentPlanId.totalInvestment",
    },
    {
      Header: "Invested Amount",
      accessor: "amount",
    },
  ];
  useEffect(() => {
    getUserByIdFunction(id);
  }, []);

  function xyz(str, x) {
    setUser((old) => ({ ...old, [str]: x }));
  }

  useEffect(() => {
    setTimeout(
      () =>
        setShowApiRes({
          message: "",
          visible: false,
          variant: "danger",
          title: "",
        }),
      5000
    );
  }, [showApiRes.visible]);
  const data = userInvestments;
  console.log(data);
  return (
    <Layout>
      <div className="flex flex-col px-10 py-8 mt-8 overflow-x-auto bg-white rounded-2xl gap-x-10 gap-y-8">
        <div className="flex">
          <div className="mr-4 cursor-pointer" onClick={() => navigate(-1)}>
            <BackIcon />
          </div>
          <div className="text-xl font-semibold">User Information</div>
        </div>
        {user && (
          <div className="w-[80%] grid grid-cols-2 gap-x-12 gap-y-8">
            <div className="">
              <h5 className="text-base font-medium "> User Id </h5>
              <p className="text-base font-regular "> {user._id}</p>
            </div>
            <div className="">
              <h5 className="text-base font-medium "> User Name </h5>
              <p className="text-base font-regular "> {user.name}</p>
            </div>
            <div className="">
              <h5 className="text-base font-medium"> Wallet Address </h5>
              <p className="text-base break-words font-regular ">
                {" "}
                {user.wallet_details.address}
              </p>
            </div>
            <div className="">
              <h5 className="text-base font-medium"> Created at </h5>
              <p className="text-base break-words font-regular ">
                {" "}
                {moment(user.createdAt).format("DD MMM YYYY")}
              </p>
            </div>
            <div className="">
              <h5 className="mb-4 text-base font-medium"> Email </h5>
              <p className="text-base font-regular "> {user.email}</p>
            </div>
          </div>
        )}
        <div className="overflow-x-auto">
          {data && data.length ? (
            <InvestmentsTable columns={columns} data={data} />
          ) : (
            <div className="pl-4">No data found</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
