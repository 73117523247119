import React from "react";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./layout.css";

function Layout(props) {
  return (
    <>
      <ToastContainer />
      <div className="min-h-screen h-full">
        <Header />
        <div className="flex">
          <div className="sidebar-container">
            <Sidebar />
          </div>
          <div className="body-container py-9 px-7 overflow-y-auto">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
